import './OrderCard.css';
import React, { useState, useContext } from "react";

function OrderCard({ orderData, openModalOrders, openModalETA }) {
	return (
		<div className="order-card">
			<OrderCardLeft orderData={orderData} openModalOrders={openModalOrders} />
			<OrderCardRight orderData={orderData} openModalETA={openModalETA} />
		</div>
	)
}

function OrderCardLeft({ orderData, openModalOrders }) {
	return (
		<div className="order-card-left" onClick={() => openModalOrders(orderData)}>
			<OrderNumber orderData={orderData} />
			<OrderInfo orderData={orderData} />
		</div>
	)
}

function OrderCardRight({ orderData, openModalETA }) {
	return (
		<div className="order-card-right">
			<OrderTimeOfArrival orderData={orderData} />
			<OrderSetETA orderData={orderData} openModalETA={openModalETA} />
		</div>
	)
}

function OrderNumber({ orderData }) {
	return (
		<div className="order-card-order-number">
			Order #{orderData["orderNumber"]}
		</div>
	)
}

function OrderInfo({ orderData }) {
	return (
		<div className="order-card-order-info">
			<span className="order-card-customer-name">
				{orderData["customerName"]}
			</span>
			•
			<span className="order-card-number-of-items">
				{orderData["numberOfItems"]}x
			</span>
		</div>
	)
}

function OrderTimeOfArrival({ orderData }) {
	return (
		<div className="order-card-time-of-arrival">
			{orderData["timeOfArrival"]}
		</div>
	)
}

function OrderSetETA({ orderData, openModalETA }) {
	let status = orderData.status;
	console.log(status);
	if (status === "new") {
		return (
			<div className="order-card-set-eta" onClick={(e) => { openModalETA(orderData["timeOfArrival"]) }}>
				set ETA
				<EditIconSVG />
			</div>
		);
	}
	else if (status === "in-progress") {
		return (
			<div className="order-card-set-eta" onClick={(e) => { openModalETA(orderData["timeOfArrival"]) }}>
				eta {orderData.timeOfArrival}
				<EditIconSVG />
			</div>
		);
	}
	else if (status === "completed") {
		return (
			<div className="order-card-completed" onClick={(e) => { openModalETA(orderData["timeOfArrival"]) }}>
				{orderData.timeOfArrival}
				<CheckmarkSVG />
			</div>
		);
	}
	else {
		return (
			<>
			Status not found
			</>
		)
	}
}

function EditIconSVG() {
	return (
		<svg className='editIconSVG' width="31" height="32" viewBox="0 0 23 32" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g filter="url(#filter0_d_1203_838)">
				<rect x="4" y="4" width="23" height="23" rx="4" fill="#F5F7F8" />
				<path d="M20.5 20.5H10.5" stroke="#28333E" stroke-width="1.2" stroke-linecap="round" />
				<path fill-rule="evenodd" clip-rule="evenodd" d="M17.1163 10.7589C17.6044 10.2708 18.3959 10.2708 18.884 10.7589C19.3722 11.2471 19.3722 12.0386 18.884 12.5267L13.707 17.7037L11.813 17.83L11.9393 15.936L17.1163 10.7589Z" stroke="#28333E" stroke-width="1.2" stroke-linecap="round" />
				<rect x="4.5" y="4.5" width="22" height="22" rx="3.5" stroke="#EBEBEB" />
			</g>
			<defs>
				<filter id="filter0_d_1203_838" x="0" y="0.5" width="31" height="31" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
					<feFlood flood-opacity="0" result="BackgroundImageFix" />
					<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
					<feOffset dy="0.5" />
					<feGaussianBlur stdDeviation="2" />
					<feComposite in2="hardAlpha" operator="out" />
					<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
					<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1203_838" />
					<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1203_838" result="shape" />
				</filter>
			</defs>
		</svg>
	);
}

function CheckmarkSVG() {
	return (
		<svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.5899 3.29827L6.20435 10.6833C5.65744 11.2304 4.77026 11.2304 4.22283 10.6833L0.410339 6.87058C-0.13678 6.32356 -0.13678 5.43628 0.410339 4.88916C0.957562 4.34194 1.84467 4.34194 2.39165 4.88895L5.21388 7.71122L11.6083 1.31676C12.1555 0.769533 13.0427 0.769948 13.5897 1.31676C14.1367 1.86387 14.1367 2.75084 13.5899 3.29827Z" fill="#525F6A" />
		</svg>
	);
}

export default OrderCard;