import "./ItemsCard.css"

const ItemsCard = ({orderNumber, station, item, options}) => {
    // Map through item options
    const itemOptions = options.map((i, index) => {
        const optionsValue = i.value.map(val => {
            return(<p className="item-card-option-value">{val.title}</p>)
        })
        return(
            <div className="item-card-option" key={index}>
                <h3 className="item-card-option-title">{i.name}</h3>
                <div>
                    {optionsValue}
                </div>
            </div>
        )
    })


    // Item Card
    return(
        <div className={`${station} item-card`}>
            <h2 className="item-card-title">{item}</h2>
            <p className="item-card-order-number">Order #{orderNumber}</p>
            <div className="item-card-option-container">
                {itemOptions}
            </div>
        </div>
    )
}
export default ItemsCard