import "./OrdersPage.css";
import HorizontalSelectorOrders from "../HorizontalSelectorOrders";
import SearchBar from "../SearchBar";
import OrderCard from "./OrderCard";
import ChangeOrderStatusButton from "../ChangeOrderStatusButton";
import React, { useState } from "react";

const OrdersPage = ({orderDatas,orderDatasOriginal,openModalOrders,openModalETA,setOrderDatas,orderStatus,setOrderStatus,filterOrderDatas,updateOrderStatus,widthClass}) => {
  // Search handler
  const [searchTerm, setSearchTerm] = useState(null)
  const handleSearch = (e) => {
    setSearchTerm(prepareSearchString(e.target.value))
  }

  // Search utils
  const prepareSearchString = (string) => {
    return String(string).toLowerCase();
  }

  // Search Filtering
  const FilteredOrders  = orderDatas.filter((orderData) => {
    if(searchTerm === null || searchTerm === ""){
      return orderData
    } else if (prepareSearchString(orderData["orderNumber"]).includes(searchTerm)){
      return orderData
    } else if (prepareSearchString(orderData["customerName"]).includes(searchTerm)){
      return orderData
    }
    for(let i = 0; i < orderData["items"].length; i++){
      let title = prepareSearchString(orderData["items"][i]["title"]);
      if(title.includes(searchTerm)){
        return orderData
      }
    }
  })
  
  const OrderContainers = FilteredOrders.map((orderData, index) => (
    <OrderContainer
      key={"order-container" + index}
      orderData={orderData}
      openModalOrders={openModalOrders}
      openModalETA={openModalETA}
      updateOrderStatus={updateOrderStatus}
    />
  ));

  let orderCardsExist = FilteredOrders.length > 0;
  return (
    <div className="orders-generic">
      <OrdersGenericHeader />
      <HorizontalSelectorOrders
        labelValues={["new", "in-progress", "needs follow-up", "completed"]}
        orderStatus={orderStatus}
        filterOrderDatas={filterOrderDatas}
        widthClass={widthClass}
      />
      <span className="order-search">
        <SearchBar placeholderText="Search by order no., name, or dish" handleSearch={handleSearch} custom={"order-search"} />
      </span>
      <div className="order-containers-spacer" />
      <div className="order-page-containers">
        {orderCardsExist && OrderContainers.reverse()}
        {!orderCardsExist && <OrderCardPlaceholder />}
      </div>
    </div>
  );
}

function OrdersGenericHeader() {
  return (
    <div className="orders-generic-header">
      Order Management
    </div>
  );
}

function OrderCardPlaceholder() {
  return (
    <div className="order-card-placeholder">There are currently no orders with this status or matching this search</div>
  );
}

function OrderContainer({
  orderData,
  openModalOrders,
  openModalETA,
  setOrderDatas,
  orderDatasOriginal,
  updateOrderStatus,
}) {
  let orderStatusButton;
  if (orderData["status"] === "new") {
    orderStatusButton = (
      <ChangeOrderStatusButton
        orderData={orderData}
        updateOrderStatus={updateOrderStatus}
        oldStatus={"new"}
        newStatus={"in-progress"}
        imageSrc={"./start_order_button_half_circle.png"}
        text={"Start <br /> Order"}
      />
    );
  } else if (orderData["status"] === "in-progress") {
    orderStatusButton = (
      <ChangeOrderStatusButton
        orderData={orderData}
        updateOrderStatus={updateOrderStatus}
        oldStatus={"in-progress"}
        newStatus={"completed"}
        imageSrc={"./green_check.png"}
        text={"Mark <br /> Complete"}
      />
    );
  } else if (orderData["status"] === "completed") {
    orderStatusButton = (
      <ChangeOrderStatusButton
        orderData={orderData}
        updateOrderStatus={updateOrderStatus}
        oldStatus={"completed"}
        newStatus={"in-progress"}
        imageSrc={"./start_order_button_half_circle.png"}
        text={"Mark <br /> in-progress"}
      />
    );
  }

  return (
    <div className="order-container">
      <OrderCard orderData={orderData} openModalOrders={openModalOrders} openModalETA={openModalETA}/>
      {orderStatusButton}
      <div className="order-card-spacer"></div>
    </div>
  );
}
export default OrdersPage
