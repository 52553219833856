import "./ScreenToggleButton.css"

const ScreenToggleButton = ({text, currentScreen, handleScreen}) => {
    return(
        <>
            <span className={`screen-toggle-button ${currentScreen === text ? "active-screen" : ""}`} onClick={e => handleScreen(text)}>
                <p className="screen-toggle-text">{text}</p>
            </span>
        </>
    )
}
export default ScreenToggleButton