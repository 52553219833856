import "./ScreenToggle.css"
import ScreenToggleButton from "./ScreenToggleButton"

const ScreenToggle = ({handleScreen, currentScreen}) => {
    return(
        <div className="screen-toggle">
            <ScreenToggleButton handleScreen={handleScreen} currentScreen={currentScreen} text={"Orders Management"}/>
            <ScreenToggleButton handleScreen={handleScreen} currentScreen={currentScreen} text={"Aggregated Orders"}/>
            <ScreenToggleButton handleScreen={handleScreen} currentScreen={currentScreen} text={"Items"}/>
        </div>
    )
}
export default ScreenToggle