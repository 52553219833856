import './OrdersAggregatedPage.css';
import HorizontalSelectorOrders from '../HorizontalSelectorOrders';
import SearchBar from '../SearchBar';
import OrdersAggregatedCard from './OrdersAggregatedCard';
import ChangeOrderStatusButton from '../ChangeOrderStatusButton';
import React, { useState } from "react";

function OrdersAggregatedPage({ orderDatasAggregated, openModalOrdersAggregated, orderStatus, filterOrderDatas, updateOrderStatus, orderLookup, widthClass }) {
	// Search handler
	const [searchTerm, setSearchTerm] = useState(null)
	const handleSearch = (e) => {
		setSearchTerm(prepareSearchString(e.target.value))
	  }

	let orderDatasAggregatedKeys = Object.keys(orderDatasAggregated).length > 0 && orderStatus in orderDatasAggregated ? Object.keys(orderDatasAggregated[orderStatus]) : [];
	function getDishData(dishKey){
		return {
			title: dishKey,
			orderIDs: orderDatasAggregated[orderStatus][dishKey]
		};
	};

	// Search utils
	const prepareSearchString = (string) => {
	return String(string).toLowerCase();
	}

	// Search Filtering
	const OrdersTitleKey = orderDatasAggregatedKeys.filter((dishKey) => {
		const dishData = getDishData(dishKey)
		if(searchTerm === null || searchTerm === ""){
			return dishKey
		} else if (prepareSearchString(dishKey).includes(prepareSearchString(searchTerm))){
			return dishKey
		}
		for(let i = 0; i < dishData["orderIDs"].length; i++){
			if(prepareSearchString(dishData["orderIDs"][i]).includes(searchTerm)){
				return dishKey
			}
		}
	})	

	const OrdersAggregatedContainers = OrdersTitleKey.map((dishKey, index) =>
		<OrdersAggregatedContainer key={ "orders-aggregated-container" + index } dishData={getDishData(dishKey)} openModalOrdersAggregated={openModalOrdersAggregated} orderLookup={orderLookup} orderStatus={orderStatus} updateOrderStatus={updateOrderStatus} />
	);
	let ordersAggregatedCardsExist = OrdersTitleKey.length > 0;
	return (
		<div className="orders-aggregated">
			<OrdersAggregatedHeader />
			<HorizontalSelectorOrders labelValues={["new", "in-progress", "needs follow-up", "completed"]} orderStatus={orderStatus} filterOrderDatas={filterOrderDatas} widthClass={widthClass} />
			<span className='aggregated-search'>
				<SearchBar placeholderText="Search by dish or order no." handleSearch={handleSearch}/>
			</span>
			<div className="orders-aggregated-containers-spacer" />
			<div className="orders-aggregated-containers">
				{ordersAggregatedCardsExist && (
				  OrdersAggregatedContainers.reverse()
				)}
				{!ordersAggregatedCardsExist && (
					<OrdersAggregatedCardPlaceholder />
				)}
			</div>
		</div>
	)
}

function OrdersAggregatedHeader(){
	return (
		<div className="orders-aggregated-header">
			Aggregated Orders
		</div>
	)
}

function OrdersAggregatedCardPlaceholder(){
	return (
		<div className="orders-aggregated-card-placeholder">
			There are currently no aggregated orders with this status or matching this search
		</div>
	)
}

function OrdersAggregatedContainer({ dishData, openModalOrdersAggregated, orderStatus, updateOrderStatus, orderLookup }){
	let orderStatusButton;
	if(orderStatus === "new"){
		orderStatusButton = <ChangeOrderStatusButton orderData={{}} updateOrderStatus={updateOrderStatus} oldStatus={"new"} newStatus={"in-progress"} imageSrc={"./start_order_button_half_circle.png"} text={"Start <br /> Order"} />
	}
	else if(orderStatus === "in-progress"){
		orderStatusButton = <ChangeOrderStatusButton orderData={{}} updateOrderStatus={updateOrderStatus} oldStatus={"in-progress"} newStatus={"completed"} imageSrc={"./green_check.png"} text={"Mark <br /> Complete"} />
	}
	else if(orderStatus === "completed"){
		orderStatusButton = <ChangeOrderStatusButton orderData={{}} updateOrderStatus={updateOrderStatus} oldStatus={"completed"} newStatus={"in-progress"} imageSrc={"./start_order_button_half_circle.png"} text={"Mark <br /> in-progress"} />
	}

	return (
		<div  className="orders-aggregated-container">
			<OrdersAggregatedCard dishData={dishData} orderLookup={orderLookup} openModalOrdersAggregated={openModalOrdersAggregated} />
			{ orderStatusButton }
			<div className="orders-aggregated-card-spacer"></div>
		</div>
	)
}

export default OrdersAggregatedPage;