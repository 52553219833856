import './ModalETA.css';
import ChangeOrderStatusInModalButton from './ChangeOrderStatusInModalButton';
import React, { useState, useContext, useEffect } from "react";

function ModalETA({ closeModalETA, ModalETAData, updateOrderStatus, widthClass }) {

  return (
    <>
      <div onClick={closeModalETA} className="overlay"></div>
      <div className={`ModalETA-${widthClass}`}>
        <button onClick={closeModalETA} className="close-button">&times;</button>
        <header className={`ModalETA-header-${widthClass}`}>
        </header>
        <main className="ModalETA-main">
          <br />
          <TimePicker ModalETAData={ModalETAData} />
          <div className="change-order-status-in-modal-button-container">
          </div>
        </main>
      </div>
    </>
  )
}


const TimePicker = ({ ModalETAData }) => {
  const [time, setTime] = useState('');

  useEffect(() => {
    if (ModalETAData) {
      const [hours, minutes] = ModalETAData.split(':');
      setTime(`${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`);
    }
  }, [ModalETAData]);

  const handleChange = (event) => {
    setTime(event.target.value);
  };

  return (
    <>
    <h2 className='modalETA-Title'>
      Select a time:<br />
      <input type="time" id="time" name="time" value={time} onChange={handleChange} step={60} timeFormat="24-hour" autoFocus/>
    </h2>
    </>
    
  );
};



export default ModalETA;