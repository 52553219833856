import { useEffect, useState } from "react"
import SearchBar from "../SearchBar"
import ItemsCard from "./ItemsCard"
import "./ItemsPage.css"

const ItemsPage = ({orderDatasOriginal}) => {
    const [orderItems, setOrderItems] = useState([])
    const [searchTerm, setSearchTerm] = useState(null)

    // Search utils
	const handleSearch = (e) => {
		setSearchTerm(prepareSearchString(e.target.value))
	  }
	const prepareSearchString = (string) => {
        return String(string).toLowerCase();
    }

    // Map to orderItems state when searchTerm changes
    useEffect(() => {
        setOrderItems([])
        console.log(orderDatasOriginal)
        orderDatasOriginal.map(e => {
            e.items.map(item => {
                let option = [] // Holding the options value
                // Map through options value
                item.options.map(itemOption => {
                    option.push(
                        {
                            name: itemOption.title,
                            value: itemOption.value
                        }
                    )
                    return null
                })
                let orderNumber = e.orderNumber
    
                // Create order
                let order = {
                    orderNumber: orderNumber,
                    item: item.title,
                    options: option
                }
    
                // Push to orderItems state
                if(searchTerm === null || searchTerm === ""){
                    setOrderItems(currentItems => [...currentItems, order])
                } else if (prepareSearchString(item.title).includes(prepareSearchString(searchTerm)) || e.orderNumber == searchTerm){
                    setOrderItems(currentItems => [...currentItems, order])
                }
                return null
            })
            return null
        }) 
    }, [searchTerm])    
    
    // Map through orders 
    const cards = orderItems.map((i, index) => {
        return <ItemsCard key={index} orderNumber={i.orderNumber} item={i.item} station={"entree"} options={i.options}/>
    })

    return(
        <div className="item-page">
            <h1 className="item-page-title">All Items</h1>

            {/* Head */}
            <div className="item-page-head">
                <span className="item-page-search">
                    <SearchBar placeholderText={"Search name, order no."} handleSearch={handleSearch}/>
                </span>

                {/* Select */}
                <select className="item-page-select">
                    <option>Choose Station</option>
                    <option>dessert</option>
                    <option>cool stuff</option>
                </select>
            </div>

            {/* Item Cards */}
            <div className="item-page-items">
                {cards}
            </div>
        </div>
    )
}
export default ItemsPage