import './SearchBar.css';
import React from "react";

function SearchBar({ placeholderText, handleSearch, custom }) {
	return (
		<div className={`search-bar`}>
			<input id={custom} className={`search-bar-input`} type="text" placeholder={ placeholderText } onKeyUp={(e) => handleSearch(e)}/>
		</div>
	)
}

export default SearchBar;