import './NavigationBar.css';
import React from "react";

function NavigationBar({ setIsOpenNavigationMenu, widthClass, toggleItemAvailabilityModal, itemAvailabilityModalIsOpen }) {

  const getScreen = () => {
    let screen=document.location.href.split('/').slice(-1)[0];
    screen = screen === "" ? "kitchen" : screen;
    return screen;
  } /* TODO: import this logic from App.js rather than running it here */

  const screen = getScreen();

  return(
    <div className="navbar">
      <img alt="hamburgerImg" className="navbar-hamburger" src="./navbar_hamburger.png" onClick={setIsOpenNavigationMenu} />
      { (screen === "kitchen" && widthClass === "wide") &&
	      <button className="navbar-kitchen-item-availability" onClick={toggleItemAvailabilityModal}>{!itemAvailabilityModalIsOpen ? 'Item Availability' : 'Kitchen Home'}</button>
	  }
    </div>
  )
}

export default NavigationBar;